<template>
  <v-card flat class="order-info mb-3">
    <v-card-title>
      <v-row no-gutters class="order-detail-info-header">
        <v-col cols="4" md="2">
          <span>{{ $t("orders.header.orderNumber") }}</span>
        </v-col>
        <v-col cols="4" md="2">
          <span>{{ $t("orders.header.status") }}</span>
        </v-col>
        <v-col cols="4" md="3">
          <span>{{ $t("orders.header.paymentType") }}</span>
        </v-col>
        <v-col cols="12" md="6"> </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters class="align-center">
        <v-col cols="4" md="2">
          <span class="order-number">#{{ order.orderId }}</span>
        </v-col>
        <v-col cols="4" md="2">
          <OrderStatusBadge v-bind:order="order" />
        </v-col>
        <v-col cols="4" md="2">
          {{ $t("orders.paymentType." + order.paymentTypeId) }}
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="order-actions d-flex justify-md-end justify-center mt-5 mt-md-0"
        >
          <v-tooltip bottom v-if="order.isPayable">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                large
                icon
                class="main-button primary mr-1"
                @click="showPayment"
              >
                <v-icon>$creditcard</v-icon>
              </v-btn>
            </template>
            <span>Ritenta pagamento</span>
          </v-tooltip>

          <OrderQrCode
            v-if="order.orderInfos.PIN_CODE"
            :orderId="order.orderId"
            :codeValue="order.orderInfos.PIN_CODE"
            :width="40"
            :height="40"
          />

          <v-tooltip bottom v-if="order.orderStatusId == 8">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                v-bind="attrs"
                v-on="on"
                icon
                class="main-button mr-1"
                target="_blank"
                :href="receiptUrl"
                @click.prevent="
                  handleReceiptDownload(receiptUrl, order.orderId)
                "
              >
                <v-icon>$receipt</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("orders.button.receipt") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="order.isEditable">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                v-bind="attrs"
                v-on="on"
                icon
                class="main-button mr-1"
                @click="editOrder(order.orderId)"
              >
                <v-icon>$edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("orders.button.edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <!-- v-if="order.orderStatusId == 8 || order.orderStatusId == 10" -->
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                v-bind="attrs"
                v-on="on"
                icon
                class="main-button mr-1"
                @click="addAllToCart(order.orderId)"
              >
                <v-icon>$cart</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("orders.button.addAllToCart") }}</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="
              order.orderStatusId != 11 &&
                !(order.orderStatusId == 1 && order.paymentTypeId == 1)
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                large
                icon
                class="main-button mr-1"
                @click="printOrder"
              >
                <v-icon>$print</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("orders.button.print") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="order.isDeletable">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                large
                icon
                class="secondary-icon-button mr-1"
                @click="deleteOrder(order.orderId)"
              >
                <v-icon>$delete</v-icon>
              </v-btn>
            </template>
            <span>Annulla ordine</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style scoped>
.order-detail-info-header span {
  word-break: break-word;
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge";
import OrderQrCode from "./OrderQrCode";
import downloadDocumentMixin from "~/mixins/downloadDocument";

export default {
  name: "OrderDetailInfo",
  mixins: [downloadDocumentMixin],
  components: { OrderStatusBadge, OrderQrCode },
  props: {
    order: { type: Object, required: true }
  },

  computed: {
    receiptUrl() {
      let orderId = this.order.orderId;
      return `/ebsn/api/order/document?document_type_id=1&type=pdf&order_id=${orderId}`;
    }
  },
  methods: {
    addAllToCart(orderId) {
      this.$emit("addAllToCart", {
        orderId
      });
    },
    deleteOrder(orderId) {
      this.$emit("deleteOrder", {
        orderId
      });
    },
    editOrder(orderId) {
      this.$emit("editOrder", {
        orderId
      });
    },
    printOrder() {
      window.print();
    },
    showPayment() {
      this.$emit("showPayment");
    }
  }
};
</script>
