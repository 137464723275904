<template>
  <div class="order-detail d-flex flex-column scroll-container">
    <v-container fluid class="d-flex flex-column scroll-container" v-if="order">
      <CategoryTitle :category="category" />
      <PaymentTypeList
        class="mb-4"
        mode="order"
        :order-id="order.orderId"
        v-if="showPayment"
      />

      <v-row class="justify-space-between">
        <v-col cols="12" md="5">
          <div
            class="d-flex flex-column flex-sm-row flex-md-column justify-space-between h-100 order-basic-info"
          >
            <CartInfoServiceCard
              class="mb-3 mb-sm-0 mb-md-3 mr-sm-3 mr-md-0"
              :shippingAddress="order.shippingAddress"
            />
            <CartInfoAddressCard
              class="mb-3 mb-sm-0 mb-md-3 mr-sm-3 mr-md-0"
              :shippingAddress="order.shippingAddress"
            />
            <CartInfoTimeslotCard
              :shippingAddress="order.shippingAddress"
              :timeslot="order.timeslot"
            />
          </div>
        </v-col>
        <v-col cols="12" md="7">
          <OrderDetailInfo
            :order="order"
            @addAllToCart="addAllToCart"
            @deleteOrder="deleteOrder"
            @editOrder="editOrder"
            @showPayment="showPayment = !showPayment"
          />

          <CheckoutSummary
            :orderCart="order"
            :isCheckout="false"
            :isOrderDetailPage="true"
          />
        </v-col>
      </v-row>

      <OrderDetailTable
        class="mt-4"
        :suborders="order.suborders"
        :showSectors="true"
      ></OrderDetailTable>

      <v-row class="actions-buttons footer-btn">
        <v-btn
          outlined
          color="secondary"
          x-large
          depressed
          v-bind:to="'/profile/orders'"
        >
          TORNA AI MIEI ORDINI
        </v-btn>
      </v-row>
    </v-container>
    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>
<style scoped lang="scss">
@media print {
  .order-basic-info {
    display: flex !important;
    flex-direction: row !important;
  }
}
</style>
<script>
// @ is an alias to /src
import OrderDetailTable from "@/components/orders/OrderDetailTable.vue";
import OrderDetailInfo from "@/components/orders/OrderDetailInfo.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import CartInfoServiceCard from "@/components/cart/CartInfoServiceCard";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import CustomConfirmDialog from "@/components/CustomConfirmDialog.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";

import categoryMixins from "~/mixins/category";
import reload from "~/mixins/reload";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import { mapState, mapActions } from "vuex";
import get from "lodash/get";

export default {
  name: "Order",
  data() {
    return {
      review: {
        rating: 3,
        comment: "",
        active: 1,
        title: "",
        noteId: null,
        productId: -1
      },
      order: null,
      valid: true,
      showPayment: false,
      requiredRules: [v => !!v || "Campo obbligatorio"]
    };
  },
  mixins: [categoryMixins, reload],
  components: {
    OrderDetailInfo,
    OrderDetailTable,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    CartInfoServiceCard,
    CheckoutSummary,
    CategoryTitle,
    PaymentTypeList
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    },
    enableSave() {
      return !this.valid;
    },
    shippingAddress() {
      let shippingAddress = get(this.order, "shippingAddress");
      if (shippingAddress) {
        return this.$t(
          "navbar.address." + shippingAddress.addressTypeId + ".format",
          shippingAddress
        );
      } else {
        return this.$t("navbar.noShippingAddress");
      }
    },
    // timeSlot() {
    //   if (this.order.timeslot) {
    //     const date = this.$dayjs(this.order.timeslot.date).format("DD MMMM");
    //     return (
    //       date +
    //       " | " +
    //       this.getTimeslotTime(this.order.timeslot.beginTime) +
    //       " - " +
    //       this.getTimeslotTime(this.order.timeslot.endTime)
    //     );
    //   }
    //   return this.$t("navbar.noTimeslot");
    // },
    serviceImg() {
      return this.$t(
        "navbar.service." +
          this.order.shippingAddress.deliveryServiceId +
          ".icon",
        {
          color: "color"
        }
      );
    }
  },
  methods: {
    // getTimeslotTime(time) {
    //   if (time) {
    //     const parseTime = this.am_pm_to_hours(time);
    //     var d = new Date();
    //     var timeMatch = parseTime.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    //     d.setHours(parseInt(timeMatch[1]) + (timeMatch[3] ? 12 : 0));
    //     d.setMinutes(parseInt(timeMatch[2]) || 0);
    //     const timeOnly = this.$dayjs(d).format("HH:mm");
    //     return timeOnly;
    //   } else return "";
    // },
    am_pm_to_hours(time) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes;
    },
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart"
    }),
    async addAllToCart({ orderId }) {
      let customPathMessage = "message.CustomAddAllFromOrder.standard";
      if (this.order.isEditable) {
        customPathMessage = "message.CustomAddAllFromOrder.isEditable";
      } else if (this.order.isDeletable) {
        customPathMessage = "message.CustomAddAllFromOrder.isDeletable";
      } else if (
        new Date().getTime() < new Date(this.order.timeslot.date).getTime()
      ) {
        customPathMessage = "message.CustomAddAllFromOrder.isStillNotDelivered";
      }

      let res = await this.$dialog.show(CustomConfirmDialog, {
        waitForResult: true,
        path: customPathMessage,
        paramContent: [orderId],
        paramContent2: [orderId]
      });
      if (res) {
        await this.addProductsFromOrder(orderId);
      }
    },
    async deleteOrder({ orderId }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });

      if (res) {
        await OrderService.deleteOrder(orderId);
        AnalyticsService.refund(this.order);
        await this.reload(orderId);
      }
    },
    async editOrder({ orderId }) {
      await OrderService.editOrder(orderId);
      this.loadCart();
      this.$router.push({
        name: "Checkout"
      });
    },
    async reload(orderId) {
      this.order = await OrderService.getOrder(orderId);
      if (this.order.isPayable) {
        this.showPayment = true;
      }
    }
  },
  created() {
    this.reload(this.$route.params.orderId);
  }
};
</script>
