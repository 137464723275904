<template>
  <div class="detail-table">
    <v-switch
      v-model="showSectors"
      label="Dividi per categoria"
      class="divide-categories-switch"
    ></v-switch>
    <v-row
      no-gutters
      v-if="!$vuetify.breakpoint.smAndDown"
      class="text-caption default--text mb-3 detail-table-header"
    >
      <v-col cols="6" sm="5">
        <strong>{{ $t("orders.header.product") }}</strong>
      </v-col>

      <v-col cols="2" sm="2" class="d-flex justify-center">
        <strong>{{ $t("orders.header.unitPrice") }}</strong>
      </v-col>
      <v-col cols="2" sm="2" class="d-flex justify-center">
        <strong>{{ $t("orders.header.qty") }}</strong>
      </v-col>
      <v-col cols="2" sm="2" class="d-flex justify-center">
        <strong>{{ $t("orders.header.totalPrice") }}</strong>
      </v-col>
      <v-col cols="0" sm="1" class="d-flex justify-center">
        <!-- <strong>{{ $t("orders.header.totalPrice") }}</strong> -->
      </v-col>
    </v-row>

    <div v-if="!showSectors">
      <v-list
        v-for="suborder in suborders"
        v-bind:key="suborder.suborderId"
        class="t-detail-list"
      >
        <OrderListItem
          v-for="item in suborder.suborderItems"
          :order="item"
          v-bind:key="item.itemId"
          :to="{ name: 'Product', params: { slug: item.product.slug } }"
        />
      </v-list>
    </div>

    <v-expansion-panels
      v-else-if="itemGroups.size >= 0 && showSectors"
      v-model="panel"
      accordion
      flat
      multiple
    >
      <v-expansion-panel
        v-for="([categoryId, group], index) in itemGroups"
        v-bind:key="categoryId"
      >
        <v-expansion-panel-header
          v-on:click.prevent="() => {}"
          class="text-caption secondary--text secondary lighten-5 px-3"
        >
          <v-row no-gutters>
            <span class="font-weight-bold mr-2">{{ group.name }}</span>
            <span>{{ `${$tc("lists.products", group.products.length)}` }}</span>
          </v-row>
          <template v-slot:actions>
            <v-icon color="secondary" x-small>
              {{ panel.includes(index) ? "$minus" : "$plus" }}
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <OrderListItem
            v-for="item in group.products"
            :order="item"
            v-bind:key="item.itemId"
            :to="{ name: 'Product', params: { slug: item.product.slug } }"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<style scoped lang="scss">
::v-deep .cur-price {
  background-color: transparent !important;
  color: var(--v-default-base) !important;
  width: auto !important;
  font-weight: 400 !important;
  display: flex !important;
  align-items: center !important;
  .descr {
    display: none;
  }
}
.v-expansion-panel {
  border-radius: 0;
  .v-expansion-panel-header {
    min-height: 20px;
    padding: 10px 24px;
  }
  .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
    padding: 16px 0px !important;
    .v-list-item {
      padding: 0;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        border-bottom: 1px solid $gray-border-color;
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
<script>
import OrderListItem from "@/components/orders/OrderListItem.vue";
import AnalyticsService from "~/service/analyticsService";

// import map from "lodash/map";
// import groupBy from "lodash/groupBy";
export default {
  name: "OrderDetailTable",
  props: {
    suborders: { type: Array },
    showSectors: { type: Boolean, default: false }
  },
  components: { OrderListItem },
  data() {
    return {
      panel: []
    };
  },
  computed: {
    itemGroups() {
      let groups = new Map();
      if (this.suborders) {
        this.suborders.forEach(suborder => {
          suborder.suborderItems.forEach(item => {
            let category = this.$store.getters["category/lookupCategory"](
              item.product.categoryId
            );
            if (category === null || category === undefined) {
              category = {
                categoryId: -1,
                name: "Senza categoria"
              };
            }
            if (groups.has(category.categoryId)) {
              groups.get(category.categoryId).products.push(item);
            } else {
              groups.set(category.categoryId, {
                name: category.name,
                products: [item]
              });
            }
          });
        });
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.panel = Array.from(Array(groups.size).keys());
      return groups;
    }
  },
  mounted() {
    for (const subOrder of this.suborders) {
      AnalyticsService.viewProducts(subOrder.suborderItems, "Order", 0);
    }
  }

  // methods: {
  //   setItemGroups(array) {
  //     let temp = groupBy(array, "product.categoryId");
  //     this.itemGroups = map(temp, products => {
  //       let category = this.$store.getters["category/lookupCategory"](
  //         products[0].product.categoryId
  //       ).name;
  //       return {
  //         category,
  //         products
  //       };
  //     });
  //     this.panel = Array.from(Array(this.itemGroups.length).keys());
  //   }
  // },
  // mounted() {
  //   let array = [];
  //   for (var i = 0; i < this.suborders.length; i++) {
  //     for (var j = 0; j < this.suborders[i].suborderItems.length; j++) {
  //       array.push(this.suborders[i].suborderItems[j]);
  //     }
  //   }
  //   this.setItemGroups(array);
  // }
};
</script>
