<template>
  <div class="qty-box">
    <!-- <span class="qnt">{{ item.quantity }}</span> -->
    <span class="val">{{ quantity }} {{ unit }}</span>
    <span class="small">{{ quantityPerUnit }}</span>
  </div>
</template>

<script>
import productMixin from "~/mixins/product";

export default {
  name: "OrderDetailQuantity",
  props: ["item"],
  mixins: [productMixin],
  computed: {
    product() {
      return this.item.product;
    }
  }
};
</script>

<style></style>
