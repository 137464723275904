<template>
  <v-list-item :to="{ name: 'Product', params: { slug: order.product.slug } }">
    <v-row no-gutters align="center">
      <v-col
        order="1"
        order-md="1"
        cols="9"
        sm="10"
        md="5"
        class="d-flex align-center mb-3 mb-md-0"
      >
        <v-img
          :src="order.product.mediaURL"
          class="product-img"
          width="85px"
          height="85px"
          max-height="85px"
          max-width="85px"
          :alt="order.product.name"
        />
        <div class="product-info">
          <span class="product-brand">{{ order.product.shortDescr }}</span>
          <span class="product-name">{{ order.product.name }}</span>
          <div class="product-descr">{{ order.product.description }}</div>
        </div>
      </v-col>

      <v-col
        order="3"
        order-md="2"
        cols="4"
        md="2"
        class="d-flex justify-center"
      >
        <span class="unit-price"> {{ order.unitPrice | currency }}</span>
      </v-col>
      <v-col
        order="4"
        order-md="3"
        cols="4"
        md="2"
        class="d-flex justify-center"
      >
        <OrderDetailQuantity :item="order" />
      </v-col>
      <v-col
        order="5"
        order-md="4"
        cols="4"
        md="2"
        offset-sm="0"
        class="d-flex justify-center"
      >
        <strong class="gross-total">
          {{ $n(order.grossTotal, "currency") }}
        </strong>
      </v-col>
      <v-col
        order="2"
        order-md="5"
        cols="3"
        sm="2"
        md="1"
        class="d-flex justify-center"
      >
        <v-btn
          color="secondary"
          class="add-to-cart"
          elevation="0"
          @click.stop.prevent="addToCart(order.product, order.quantity)"
        >
          <v-icon>$cart</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-list-item>
</template>
<style scoped lang="scss">
.gross-total {
  font-size: 16px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 14px;
  }
}
.product-name {
  display: block;
}
</style>
<script>
import OrderDetailQuantity from "@/components/orders/OrderDetailQuantity";

import { mapActions } from "vuex";

export default {
  props: {
    order: { type: Object, required: true }
  },
  components: {
    OrderDetailQuantity
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    addToCart(product, quantity) {
      this.addProduct({ product: product, quantity: quantity });
    }
  }
};
</script>
